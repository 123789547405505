import { getUserSig } from '@/api/api'
import { removeAll } from '@/utils/auth.js';
export default {
    getUserSig({ state }) {
        return new Promise((resolve, resect) => {
            getUserSig().then(res => {
                console.log('user_sig', res);
                resolve(res.data.user_sig)
            })
        })
    },
    ACT_USER_LOGOUT({ state }) {
        state.TIM && state.TIM.destroy()
        removeAll()
        location.reload()
    },
}