<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { createIM,LoginIM } from '@/utils/im';
import { getUserInfo } from '@/utils/auth'
export default{
  data(){
    return{
    }
  },
  mounted(){
    
    // createIM();
    let userInfo = getUserInfo()
    if(Object.keys(userInfo).length>0 ){
      this.$store.commit('UPDATE_USER_INFO', userInfo)
      // LoginIM(userInfo.id);
    }
  }
}
</script>
<style lang="scss">
@import '~@/assets/css/variable.scss';
</style>
