// 授权相关接口
import { post } from '@/utils/request'

// 登录服务接口
export const ServeLogin = data => {
  return post('v1/5c78dbfd977cf', data)
}


// 退出登录服务接口
export const ServeLogout = data => {
  return post('v1.Chat/LogOut', data)
}

// 获取我的直播
export const getLiveList = data => {
  return post('v2/6430d3a443be5', data)
}

// 处理连麦混合音频推流到cdn
export const setLiveMix = data => {
  return post('v2/6468c71184068', data)
}

// 修改直播间状态
export const changeStatus = data => {
  return post('v2/5fc9fd907ae13', data)
}

// 观众上麦/下麦
export const changeJoinMic = data => {
  return post('v2/64488553a86da', data)
}

// 获取usersig
export const getUserSig = data => {
  return post('v2/6620cf510393b', data)
}

// 获取用户信息
export const getUserInfo = data => {
  return post('v1/5c78c4772da97', data)
}

//存储直播观看人数
export const saveLiveRecord = data => {
  return post('v2/664ef92db69d3', data)
}

//增加直播查看记录
export const watchLive = data => {
  return post('v2/643136f520431', data)
}

//离开房间
export const leaveLive = data => {
  return post('v2/667d1a5837931', data)
}