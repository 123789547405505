import JsBase64 from 'js-base64'

const USER_TOKEN = 'JM-TOKEN'
const USER_INFO = 'JM-USERINFO'
const USER_SETTING = 'LUMENIM_SETTING'

import CryptoJS from 'crypto-js'
let key = 'hfb6540l38b489d9f306a5b8e105334b',
	iv = 'hfb5cd0045222c52'

/**
 * 设置用户授权token
 *
 * @param {String} token
 * @param {Number} expires
 */
export function setToken(token,expires) {
  expires = new Date().getTime() + expires * 1000 - 100 * 1000
  return sessionStorage.setItem(
    USER_TOKEN,
    JSON.stringify({
      token,
      expires,
    })
  )
}

/**
 * 获取授权token
 */
export function getToken() {
  const result = JSON.parse(
    sessionStorage.getItem(USER_TOKEN) ||
      JSON.stringify({
        token: '',
        expires: 0,
      })
  )

  let t = new Date().getTime()
  if (result.expires <= t) {
    sessionStorage.removeItem(USER_TOKEN)
    return ''
  }

  return result.token
}

/**
 * 设置用户信息
 *
 * @param {Object} data
 */
export function setUserInfo(data) {
  sessionStorage.setItem(USER_INFO, JsBase64.Base64.encode(JSON.stringify(data)))
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
  const data = JsBase64.Base64.decode(sessionStorage.getItem(USER_INFO) || '')
  return data ? JSON.parse(data) : {}
}

/**
 * 获取用户本地缓存的设置信息
 */
export function getUserSettingCache() {
  const data = sessionStorage.getItem(USER_SETTING)
  return data ? JSON.parse(data) : {}
}

/**
 * 用户设置保存到浏览器缓存中
 *
 * @param {Object} state 用户设置相关信息
 */
export function setUserSettingCache(state) {
  sessionStorage.setItem(USER_SETTING, JSON.stringify(state))
}

/**
 * 删除用户相关缓存信息
 */
export function removeAll() {
  // sessionStorage.removeItem(USER_TOKEN)
  // sessionStorage.removeItem(USER_INFO)
  // sessionStorage.removeItem(USER_SETTING)
  sessionStorage.clear()
  localStorage.clear()
}

export function encrypt(data) {
  let encrypt = CryptoJS.AES.encrypt(data,CryptoJS.enc.Utf8.parse(key),{
    iv:CryptoJS.enc.Utf8.parse(iv),
    mode:CryptoJS.mode.CBC,
    padding:CryptoJS.pad.Pkcs7
  })
  return encrypt.toString()
}
