import TencentCloudChat from '@tencentcloud/chat';
import store from '@/store/index.js'
import eventBus from '@/utils/eventBus.js';
import config from '@/config/config'
let options = {
	SDKAppID: config.APPID // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};

let chat = null
// 初始化
function createIM() {
	chat = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示
    console.log('chat-->',chat);
	store.commit('SET_TIM', chat)
	chat.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用

	let onMessageReceived = function(event) {
	  // event.data - 存储 Message 对象的数组 - [Message]
	  // Message 数据结构详情请参考 https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html
      eventBus.$emit('exit');
	};
	chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
	
	let onSdkReady = function(event) {
		chat.updateMyProfile({
			nick: store.state.userInfo.user_nickname,
			avatar: store.state.userInfo.head_img,
		});
	};
	chat.on(TencentCloudChat.EVENT.SDK_READY, onSdkReady);
}
// 登录
async function LoginIM(userId) {
	
	let userSig = await store.dispatch('getUserSig')
	// console.log('userSig',userSig);
	let promise = chat.login({
		userID: 'live_user_id_' + userId,
		userSig: userSig
	});
	promise.then((imResponse) => {
		console.log('登录成功');
		console.log(imResponse.data); // 登录成功
		console.log(imResponse.data.errorInfo);
	}).catch(function(imError) {
		console.warn('login error:', imError); // 登录失败的相关信息
	});
}

// 发送文本消息
function createTextMessage(groupId,text) {
	return new Promise((resolve,reject)=>{
		let message = chat.createTextMessage({
			to: groupId,
			conversationType: TencentCloudChat.TYPES.CONV_GROUP,
			payload: {
				text: text
			},
		});
		// 2. 发送消息
		let promise = chat.sendMessage(message);
		promise.then(function(imResponse) {
			// 发送成功
			console.log(imResponse);
			resolve(imResponse)
		}).catch(function(imError) {
			// 发送失败
			console.warn('sendMessage error:', imError);
		});
	})
}

// 发送IM群组消息 
// type  9 讲解商品， 10 取消讲解, 11 售卖， 12 取消售卖, 13 音乐管理, 14 音乐列表点击 15 开始直播
function sendCustomCmdMsg(groupId,type,description='',extension=''){
	return new Promise((resolve,reject)=>{
		// 发送支持消息扩展的群消息
		let message = chat.createCustomMessage({
			to: groupId,
			conversationType: TencentCloudChat.TYPES.CONV_GROUP,
			payload: {
				description: description,
				data:type,
				extension:extension,
			},
		});
		// 发送消息
		let promise = chat.sendMessage(message);
		promise.then(function(imResponse) {
			// 发送成功
			console.log(imResponse);
			resolve(imResponse)
		}).catch(function(imError) {
			// 发送失败
			console.warn('sendMessage error:', imError);
		});
	})
	
}

export {
	createIM,
	LoginIM,
	createTextMessage,
	sendCustomCmdMsg,
}